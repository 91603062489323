:root {
  --theme-yellow-color: #e3d000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* popins{
    font-family: 'Poppins', sans-serif;
} */
/* PT Sans{
    font-family: 'PT Sans', sans-serif;
} */
/* /////////Header css//////// */
.Header-Main {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2%;
  z-index: 7;
}
body{
  background-color: #E5FBFF;
}
ul,
ol,
dl {
  padding-left: 0;
  margin-bottom: 0;
}
.Header-Main ul {
  padding-left: 0;
}
.Header-Main ul li.hovermenus.active img {
  opacity: 1;
}
.Header-Main ul li.hovermenus.active span {
  color: #001a62;
}
@font-face {
  font-family: "TimeNewBold";
  src: url(../Fonts/TimesNewRoman/times\ new\ roman\ bold.ttf);
}
.container-1440 {
  width: 1440px;
  margin: 0 auto;
}
.header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header ul li {
  list-style: none;
}
.header ul li a {
  text-decoration: none;
  font-family: "TimeNewBold";
  font-size: 16px;
  font-weight: bold;
}
.header ul .hovermenus img {
  width: 120%;
  opacity: 0;
  transition: 0.5s ease-in-out;
  position: absolute;
  left: -6px;
  height: 100%;
  top: 0px;
  object-fit: contain;
}

.header ul .hovermenus:hover img {
  opacity: 1;
  color: #001a62;
}

.header ul li a span {
  z-index: 9;
  position: relative;
  color: #ffff;
}
.header ul li :hover span {
  color: #001a62;
  transition: ease-in-out 0.5s;
}

.header ul li.hovermenus {
  position: relative;
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  padding: 18px 21px;
}
.header-register {
  background-color: var(--theme-yellow-color);
  padding: 10px 20px;
  border-radius: 5px;
  color: #001a62 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

/* ////////////Footer///////// */
.footer {
  background-image: url(../images/FooterGreen-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: -180px;
}
.footer-left-text a {
  color: #3d3d3d;
  text-decoration: none;
}
.Footer-content {
  display: flex;
  justify-content: space-between;
  padding-top: 260px;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.footer-left-text h4 {
  font-size: 40px;
  color: #3d3d3d;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.footer-right-text h4 {
  font-size: 40px;
  font-family: "PT Sans", sans-serif;
  color: #3d3d3d;
  font-weight: bold;
  text-transform: uppercase;
}
.footer-left-text ul {
  list-style: none;
}
.footer-left-text ul li {
  margin: 20px 0;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.footer-top {
  display: flex;
  justify-content: end;
  padding-right: 40px;
}
.footer-map {
  background-image: url(../images/Footerpurble-bg.png);
  background-size: 100% 100%;
  width: 60%;
  text-align: center;
  background-repeat: no-repeat;
}
.footer-map h2 {
  padding-top: 120px;
  font-family: "PT Sans", sans-serif;
  font-size: 60px;
  font-weight: bold;
  color: #3d3d3d;
  text-transform: uppercase;
}
.footer-map p {
  padding: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #3d3d3d;
}
.footer-map h3 {
  text-transform: uppercase;
  font-size: 40px;
  color: #3d3d3d;
  font-weight: bold;
  font-family: "PT Sans", sans-serif;
}

/* ///////////About css////////////// */
.About-banner {
  background: radial-gradient(#d23fd9, #b104b5);
  /* background-image: radial-gradient(#f857ff, #b104b5); */
  overflow: hidden;
  width: 100%;
  height: 80vh;
  position: relative;
}
.banner-content {
  padding-top: 20%;
  display: flex;
  height: 500px;
}
.About {
  overflow-x: hidden;
}
.Banner-text {
  width: 50%;
}
.Banner-text h1 {
  color: var(--theme-yellow-color);
  font-size: 75px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.Banner-text h3 {
  color: #ffff;
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.Banner-pic {
  width: 50%;
}
.Banner-pic img {
  opacity: 0.1;
  width: 30%;
  position: absolute;
  top: 15%;
  right: 20%;
}

.bannerbottom {
  overflow-x: hidden;
  width: 100%;
  height: 16rem;
  /* filter: brightness(5); */
}
.bannercircle {
  position: absolute;
  bottom: 16%;
  left: 62%;
  z-index: 9;
}
.sec1 {
  margin-top: -16rem;
  position: relative;
  z-index: 8;
}
.sec1-text {
  padding-top: 70px;
  /* padding-left: 90px; */
}
.sec1-text h2 {
  font-size: 60px;
  color: #fd8531;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.sec1-text p {
  font-family: "Poppins", sans-serif;
  padding: 0px 0;
  font-size: 16px;
  font-weight: 400;
}
.sec1bg {
  width: 35%;
  opacity: 0.1;
  position: absolute;
  left: 50%;
  top: 55%;
  z-index: 9;
  transform: translate(-50%, -50%);
}
.sec1right {
  position: absolute;
  right: 0;
  width: 34%;
}
.sec1circle {
  position: absolute;
  left: 0;
  top: 43%;
}
.sec2 {
  background-color: #f3f3f3;
  margin-top: 60px;
  padding: 30px 0;
}
.sec2 h2 {
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  font-family: "PT Sans", sans-serif;
  color: #800383;
  padding: 20px 0;
}
.sec2-service {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.Service-box {
  width: 465px;
  height: 436px;
  text-align: center;
  background-image: url(../images/Service-box.png);
  box-shadow: 0 0 50px #ccc;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: white;
  z-index: 9;
}
.Service-box img {
  margin: 20px 0;
}
.Service-box p {
  margin-top: 25px;
  font-size: 15px;
  width: 90%;
  margin: 0 auto;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.Service-box h3 {
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.sec-solongop {
  text-align: center;
  padding: 30px 0;
  font-size: 20px;
  font-weight: bold;
  font-family: "PT Sans", sans-serif;
  text-transform: uppercase;
}
.sec3-content {
  background-image: url(../images/Sec3-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 50px;
  padding-bottom: 20px;
}
.About-travis-content{
  background-image: url(../images/about-travis-bg.png);
  padding-top: 20px;
}
.sec3-content h2 {
  font-size: 60px;
  font-family: "PT Sans", sans-serif;
  text-align: center;
  font-weight: bold;
  padding: 40px 0;
  color: #ffff;
  text-transform: uppercase;
}
.sec3-Sharon {
  display: flex;
  /* justify-content: space-between; */
}
.sec3-inner-content {
  width: 60%;
  margin: 0 auto;
  padding: 90px 0;
}
.sec3-Certified {
  list-style: none;
}
.service-circle {
  position: absolute;
  right: -120px;
  top: -60px;
}
.service-bg {
  position: absolute;
  left: -17%;
  top: 30%;
  opacity: 0.2;
}
.sec3-Certified li {
  color: #ffff;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  margin: 5px 0;
  font-weight: 500;
}
.sec3 {
  color: white;
  padding: 10px 30px;
}
.sec3 h3 {
  font-size: 35px;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffff;
}
.sec3-importance p {
  margin: 30px 0;
  font-size: 17px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: white;
}
/* -------HOME PAGE STYLES------- */
.bg-banner-gradient {
  background: radial-gradient(#d23fd9, #b104b5);
}
.banner-home-sec {
  overflow-x: clip;
  overflow-y: visible;
  z-index: 6;
  height: 100vh;
}
.Meet-our-team-bg {
  position: absolute;
  left: -2%;
  top: -8%;
  opacity: 0.1;
  width: 45%;
  z-index: -1;
}

.left-banner-home-btn {
  border-radius: 0.625rem;
  padding: 0.35rem 0.6rem;
  font-size: 17px;
  letter-spacing: 0.3px;
  font-family: "PT Sans", sans-serif;
  text-decoration: none;
  border: 1px solid var(--theme-yellow-color);
}
.pull-pulse :hover {
  animation: pulse 1s infinite;
  transition: 0.3s;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.left-banner-home-btn-bg-yell {
  color: #001a62;
  background-color: var(--theme-yellow-color);
}

.left-banner-home-btn-trans {
  color: var(--theme-yellow-color);
}

.banner-home-first-heading {
  font-family: "Poppins", sans-serif;
  color: var(--theme-yellow-color);
  font-size: 3.75rem;
  font-weight: 400;
}
.banner-home-second-heading {
  font-family: "Poppins", sans-serif;
  font-size: 4.8rem;
  font-weight: 600;
}
.content-parent-banner-home {
  display: flex;
  align-items: center;
  height: 100%;
}
.banner-home-para {
  font-size: 18px;
  font-family: "PT Sans", sans-serif;
  text-transform: uppercase;
}
.right-banner-home-img-lower {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50rem;
  height: 40rem;
}
.right-banner-home-img-upper {
  z-index: 3;
  width: 85%;
  position: relative;
  bottom: 1rem;
}
.right-banner-home-img {
  text-align: center;
}
.left-bottom-home-banner {
  bottom: 3rem;
}
.left-bottom-home-banner img {
  width: 85%;
}
.content-parent-banner-home {
  z-index: 6;
}
.banner-home-bg-slide {
  top: 22%;
}
.banner-home-bg-slide {
  font-family: "Poppins", sans-serif;
  color: #ffffff36;
  font-weight: 700;
  font-size: 10rem;
  letter-spacing: 0.2rem;
}
/* .banner-home-bg-slide-one {
  transform: translateX(100%);
}
.banner-home-bg-slide-two {
  transform: translateX(-100%);
} */

.banner-home-bg-slide-one,
.banner-home-bg-slide-two {
  width: max-content;
  animation-name: Banner-home-bg-slide-one;
  animation-iteration-count: infinite;
  position: absolute;
  animation-timing-function: linear;
}
.banner-home-bg-slide-one {
  animation-name: Banner-home-bg-slide-one;
  right: 22rem;
  animation-duration: 15s;
}
.banner-home-bg-slide-two {
  animation-name: Banner-home-bg-slide-two;
  left:44rem;
  animation-duration: 20s;
  top: 38%;
}

/* @keyframes Banner-home-bg-slide-one {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-380%);
  }
}
@keyframes Banner-home-bg-slide-two {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(165%);
  }
} */

.home-banner-float-anim {
  animation-name: home-banner-float-anim;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}

@keyframes home-banner-float-anim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.cloudimg-home {
  position: relative;
  z-index: 3;
}
.sec1circle-1add{
  top: 30% !important;
}
.homeabout-sec-btm-left-circle {
  position: absolute;
  bottom: 9rem;
}
.home-about-btn {
  text-decoration: none;
  font-size: 20px;
  color: #001a62;
  border-radius: 0.625rem;
  background-color: var(--theme-yellow-color);
  padding: 0.5rem 0.8rem 0.65rem 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.home-about-btn svg {
  font-size: 15px;
  padding-left: 0.7rem;
}
.homeabout-sec {
  padding-bottom: 5rem;
}
.homeabout-sec-btm-left-circle img {
  width: 80%;
}
.home-services-sec {
  background-image: url(../images/home-services-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 16rem;
  margin-top: 10rem;
  z-index: 9;
}

.left-banner-home-btn-parent {
  position: relative;
  z-index: 3;
}

.home-serv-heading {
  font-size: 3.75rem;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 2.5rem;
}
.services-hometext {
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.img-text-parent-of-home-serv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}
.services-homeimg {
  max-width: 115px;
}
.left-kid-home-serv-parent {
  position: absolute;
  transform: translate(-4rem, -14.5rem);
}
.content-parent-home-services-sec {
  padding: 5rem 0 0 0;
}
.home-services-row-gap {
  margin-bottom: 2.5rem;
}
.home-serv-top-img {
  position: absolute;
  top: -3rem;
  left: 2rem;
}
.home-esdm-heading {
  color: #fd8531;
  font-size: 3.75rem;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
}
.sub-heading-esdm-home {
  font-size: 18px;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}
.sub-para-esdm-home,
.sub-content-esdm-bullet-points li {
  font-size: 15px;
  color: #474747;
  font-family: "Poppins", sans-serif;
}

.col-content-homesdm {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding-top: 4.5rem;
}
.home-esdm-btn-parent {
  margin-top: 2rem;
}
.HomeEsdmShape-parent {
  right: -4px;
  background-color: #E5FBFF;
  width: 101%;
}
.HomeEsdmShape {
  width: 100%;
  margin-top: -2px;
}
.right-esdm-home-img-parent {
  text-align: center;
  position: absolute;
  right: 7rem;
  top: 7.5rem;
  z-index: 9;
}

.right-esdm-home-img-parent img {
  width: 85%;
}
.home-raise-sec {
  background-image: url(../images/Home-Raise-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10rem 0 3rem 0;
  position: relative;
  z-index: 4;
}

.heading-home-raise {
  text-transform: uppercase;
  color: #fd832e;
  font-size: 1.75rem;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.home-raise-btn-parent .home-about-btn {
  background-color: #fd832e;
  color: #fff;
}
.home-esdm-sec {
  padding-bottom: 6rem;
  position: relative;
  z-index: 1;
}

.footer-and-contact-parent,
.home-meet-sec {
  position: relative;
  z-index: 1;
}
.heading-home-meet-team {
  text-transform: uppercase;
  color: #fd8531;
  font-size: 3.75rem;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 3rem;
  margin-left: 4rem;
}
.right-home-meet-heading {
  text-transform: uppercase;
  color: #fd8531;
  font-size: 2.1rem;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
}
.right-home-meet-para {
  font-size: 17px;
  color: #474747;
  font-family: "Poppins", sans-serif;
}
.right-content-home-meet {
  margin-left: 4rem;
}
.home-meet-sec {
  margin-top: -2px;
}
.home-meet-sec-row {
  width: 75%;
  position: relative;
  margin: 0 auto;
}
.home-meet-sec {
  padding: 3rem 0 7rem 0;
}
.left-img-team-meet img {
  width: 120%;
}
.homeabout-sec-btm-right-circle {
  position: absolute;
  right: 33rem;
  top: -6rem;
  z-index: 9;
}
.homeabout-sec-btm-right-circle img {
  width: 80%;
}
.fixed-btm-home-page-arrow {
  position: fixed;
  bottom: 4%;
  z-index: 99;
  width: 100%;
  text-align: center;
}
.fixed-btm-home-page-arrow svg {
  cursor: pointer;
  color: #800383;
  border: 2px solid #800383;
  border-radius: 50px;
  padding: 0.6rem 0.3rem;
  font-size: 23px;
  transition: all 0.3s ease-in-out;
}
.fixed-btm-home-page-arrow svg:hover {
  background-color: #800383;
  color: #fff;
}

/* Map STYLES */
.mapouter {
  /* clip-path: polygon(0% 50%, 20% 100%, 60% 100%, 80% 50%, 60% 0%, 20% 0%); */
  position: relative;
  height: 300px;
  width: 500px;
  background: #fff;
  margin-left: 1rem;
}

.gmap_canvas {
  overflow: hidden;
  height: 300px;
  width: 500px;
  z-index: 1;
}

.gmap_canvas iframe {
  position: relative;
  z-index: 2;
}
.map-contact-parent,
.mapouter {
  display: flex;
  justify-content: center;
}
/* Map STYLES */

.home-banner-header-parent {
  position: fixed;
  width: 100%;
  z-index: 1;
}
.pd-top-100 {
  padding-top: 105vh;
  position: relative;
}

.footer-top,
.home-meet-sec,
.home-esdm-sec,
.home-about-min-parent {
  background-color: #E5FBFF;
}
.home-esdm-sec:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -140px;
  background: #E5FBFF;
  width: 100%;
  height: 100%;
  z-index: -8;
}
.homeabout-sec {
  position: relative;
}
.homeabout-sec::before {
  content: "";
  position: absolute;
  bottom: -25%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #E5FBFF;
}
.home-about-min-parent {
  position: relative;
  z-index: 9;
}
/* -------HOME PAGE STYLES------- */

/* -------ESDM THERAPY--------- */
.Main-Therapy {
  overflow-x: clip;
}
.Why-Therapy-Choose h2 {
  text-align: left !important;
}
img.sec2bg-therapy2 {
  width: 100%;
}
.sec3Img-new-add1{
  position: absolute;
  right: 5%;
  width: 40%;
}
.sec3Img-new-add2{
  position: absolute;
  right: -2%;
  top: 40%;
  width: 40%;

}
.sec1-text ul {
  margin-left: 1rem;
}
.sec1Therapy {
  right: 11%;
}

.therapyCircle1 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.Therapysec2 {
  position: relative;
  z-index: 1;
}

.sec1.Therapysec1 .therapyCircle1 {
  bottom: 0;
  top: inherit;
  left: 0;
  z-index: -1;
}
.sec2Circle {
  position: absolute;
  right: -87px;
  top: 25%;
  width: 12%;
}
.sec1bg-therapy {
  opacity: 0.1;
  position: absolute;
  left: 32%;
  top: 16%;
  z-index: 0;
  width: 35%;
}
img.therapy-sec2-pic {
  /* z-index: 1; */
  position: absolute;
  right: 7%;
  width: 25%;
}
.therapy-sec2-pic-new{
  top: 65%;
}
.Therapysec1{
  padding-bottom: 50px;
}
.therapy-sec2-childrenpic{
  right: 13% !important;
  top: 41%;
  z-index: 1;
}
.sec2bg-therapy {
  opacity: 0.1;
  position: absolute;
  left: 8%;
  top: 3%;
  z-index: -9;
  width: 30%;
}
.therapyImg2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec3 {
  margin-top: 1rem;
  color: #404040;
  position: relative;
}
.sec3 h6 {
  font-size: 40px;
  color: #ee3f95;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.sec1.Therapysec1.program-sec {
  padding-bottom: 30px;
}
.sec3 h5 {
  margin: 20px 0;
  text-transform: uppercase;
  font-family: "PT Sans";
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
}
.sec1-text ul li {
  font-size: 16px;
}
.sec3Img {
  margin-top: 120px;
  width: 100%;
}
.sec3Circle {
  position: absolute;
  top: -90px;
  left: -83px;
  z-index: 9;
  width: 12%;
}

/* OUR PROGRAM */
.Protext1 p {
  font-family: "poppins";
  font-size: 16px;
}
.Protext1 ul li {
  font-family: "poppins";
  font-size: 16px;
}
.Protext1 h6 {
  font-size: 40px;
  font-weight: bold;
  color: #fd8531;
  text-transform: uppercase;
}
.Protext1 h5 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 25px;
}
.Protext1 ul {
  margin: 15px 0 30px 18px;
}
.ClinicalImg1 {
  width: 90%;
  position: relative;
  left: 20%;
  top: 9%;
}
.ClinicalImg2 {
  width: 90%;
  position: relative;
  left: 3%;
  top: -4%;
}
.sec1bg-program {
  width: 30%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 14%;
  opacity: 0.2;
}
.Protext2 {
  margin-top: 100px;
}
.Protext2 h6 {
  color: #800383;
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
}
.Protext2 p {
  font-size: 16px;
}
.Protext2 ul li {
  font-size: 16px;
  margin-left: 5px;
}
.Protext2 ul {
  margin-bottom: 30px;
}
.Protext2 h3 {
  margin: 7px 0;
  color: #3b3b3b;
  font-size: 25px;
  text-transform: capitalize;
}
.KinderImg1 {
  position: absolute;
  width: 25%;
  top: 7%;
  left: 18%;
}
.KinderImg2 {
  position: absolute;
  width: 25%;
  top: 33%;
  left: 11%;
}
.KinderImg3 {
  position: absolute;
  width: 25%;
  top: 58%;
  left: 17%;
  z-index: -9;
}

.CasualImg1 {
  position: absolute;
  width: 25%;
  right: 15%;
  top: 8%;
}
.CasualImg2 {
  position: absolute;
  width: 25%;
  right: 7%;
  top: 31%;
}
.CasualImg3 {
  position: absolute;
  width: 25%;
  right: 15%;
  top: 59%;
  z-index: -9;
}
.ProSec2 {
  background-color: #f3f3f3;
  margin-top: 60px;
}
.ProSec2bg {
  position: absolute;
  left: -280px;
  top: -25px;
}
.Protext3 h6 {
  color: #ee3e94;
  text-transform: uppercase;
}
.Protext3 {
  color: #3b3b3b;
  margin-top: 120px;
}
.Protext3 h3 {
  color: #3b3b3b;
  margin: 7px 0;
  font-size: 25px;
  text-transform: capitalize;
}

.ProSec3bg {
  position: absolute;
  top: 3%;
  left: 914px;
  opacity: 0.1;
}
.ProSec4 h6 {
  color: #339dd7;
  text-transform: uppercase;
}
.program-sec ul {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.program-sec .sec1-text {
  padding-left: 0;
  padding-top: 0;
  margin-top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.program-sec {
  padding-left: 0;
  padding-right: 0;
}
.program-sec .sec1-text br {
  display: none;
}
.sec1.program-sec-mg-0 {
  margin-top: 0 !important;
}
.program-sec .bannercircle-rotate {
  width: 10%;
}
.program-sec-one-top-left-circ {
  position: absolute;
  top: 35%;
  left: 0;
  width: 8% !important;
}
.program-sec-one-center-right-circ {
  position: absolute;
  top: 55%;
  right: -4%;
}
.program-left-align-bg-img,
.program-right-align-bg-img {
  position: absolute;
  top: 0;
  opacity: 0.1;
  width: 30%;
}
.program-left-align-bg-img {
  left: 0;
}
.program-right-align-bg-img {
  right: 0;
}
.program-banner .container-1440 {
  position: relative;
}
.program-padding-sec {
  padding: 2.5rem 0;
}
.Protext5 h4 {
  color: #95c93d;
  font-size: 1.8rem;
  font-weight: bold;
}
.EnrollImg1 {
  position: absolute;
  width: 25%;
  left: 60%;
}
.EnrollImg2 {
  position: absolute;
  top: 20%;
  width: 25%;
  left: 67%;
  z-index: -1 !important;
}
.EnrollImg3 {
  position: absolute;
  top: 37%;
  width: 25%;
  left: 60%;
  z-index: 9;
}
.EnrollImg4 {
  position: absolute;
  top: 55%;
  width: 25%;
  left: 67%;
}
.EnrollImg5 {
  position: absolute;
  top: 75%;
  width: 25%;
  left: 61%;
  z-index: -9;
}
.program-padding-top-bottom {
  padding-top: 2.5rem;
  padding-bottom: 8rem;
}
.circle1-sec2 {
  position: absolute;
  left: 95%;
  top: 25%;
}
.circle1-sec3 {
  position: absolute;
  top: 64%;
  left: 1%;
}
.circle2-sec3 {
  position: absolute;
  top: 22%;
  left: -4%;
}

/* -------ESDM THERAPY--------- */

/* ////////////Register Modal////////// */

.Register-modal .modal-dialog.modal-dialog-centered {
  background-image: url(../images/modal-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 70%;
  /* display: block; */
}
.modal-xmark {
  position: absolute;
  right: 0;
  font-size: 30px;
  color: #fd832e;
  top: 4%;
  cursor: pointer;
}
.modal-content {
  display: block;
  background: transparent;
  border: none;
  padding-bottom: 10px;
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
.Therapy-Registration-Modal h2 {
  text-align: center;
  color: #fd832e;
  font-size: 60px;
  font-weight: bold;
  font-family: "PT Sans", sans-serif;
}
.Therapy-Registration-Modal p {
  text-align: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  /* padding-top: 20px; */
  color: #474747;
}
.Therapy-Registration-Modal h3 {
  text-align: center;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  color: #474747;
  margin-top: 30px;
}
/* .input-forms {
  width: 90%;
  margin: 40px auto;
} */
.information-forms {
  margin-bottom: 20px;
}
.information-forms label {
  display: block;
  margin: 5px 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #474747;
}
.information-forms input,
.information-forms textarea {
  width: 100%;
  outline: none;
  border-radius: 10px;
  padding: 7px;
  border: 1px solid #141413;
  background-color: transparent;
}
.information-forms textarea {
  resize: none;
  min-height: 90px;
}
/* .Other-information {
  width: 90%;
  margin: 0 auto;
} */
.Other-information input {
  height: 100px;
}
.Modal-send-btn {
  text-align: end;
  margin-top: 35px;
}
.Modal-send-btn .send {
  color: #ffff;
  background-color: #fd832e;
  padding: 6px 30px;
  border-radius: 5px;
  font-size: 20px;
  font-family: "PT Sans", sans-serif;
  text-decoration: none;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

/* contact us */
.sec1-contact {
  position: relative;
}
.bannerbottom-contact {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  z-index: 0;
}
section.sec1-contact-page {
  margin-top: -19rem;
  z-index: 9;
  position: relative;
}
.sec2-contact-page {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 17rem;
  overflow-x: hidden;
  background-color: #e5fbff;
}
.contact-infocontact-info {
  display: flex;
  align-content: space-around;
  justify-content: center;
  box-shadow: 0 0 50px #ccc;
  width: 84%;
  margin: 0 auto;
}

.info-left {
  background-color: #1e1e1e;
  background-image: url(../images/contact-page-img.png);
  width: 39.9rem;
  height: 39.9rem;
  padding-left: 5rem;
  padding-top: 3.4rem;
  clip-path: polygon(
    0% 15%,
    0 0,
    61% 0,
    100% 0,
    100% 0,
    100% 15%,
    100% 85%,
    100% 100%,
    59% 94%,
    0 100%,
    0 100%,
    0% 85%
  );
}
.info-right {
  width: 36rem;
  padding-left: 1rem;
  padding-top: 2.5rem;
  height: 100%;
  background-color: #cfb5da;
  clip-path: polygon(
    0% 15%,
    0 0,
    58% 6%,
    100% 0,
    100% 0,
    100% 15%,
    100% 85%,
    100% 100%,
    85% 100%,
    15% 100%,
    0 100%,
    0% 85%
  );
}
.text-info-contact {
  color: white;
}
.text-info-contact-p {
  font-family: "Poppins";
  width: 85%;
  font-size: 17px;
}
.text-info-contact-h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 75px;
  font-family: "PT Sans";
}
.text-info-contact-h2 {
  text-transform: uppercase;
  padding: 0.8rem;
  font-weight: bold;
  font-size: 60px;
  font-family: "PT Sans";
}
.contact-details-info svg {
  color: white;
}
.contact-details-info {
  margin-top: 2rem;
}
.phone-info {
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.email-info {
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.country-info {
  margin-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
.contact-details {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.contact-input {
  width: 25.18rem;
  background-color: transparent;
  border: 1px solid #141413;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  outline: none;
}
.contact-details textarea {
  width: 25.18rem;
  height: 7.81rem;
  background-color: transparent;
  border: 1px solid #141413;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  outline: none;
}
.contact-before {
  background-color: #cfb5da;
}
.contact-after {
  background-color: black;
}
.contact-send-btn {
  width: 10.31rem;
  height: 3.56rem;
  margin-top: 3rem;
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 0.5rem;
  border: 1px solid black;
}
.bannercircle {
  position: absolute;
  bottom: 0%;
  left: 62%;
  z-index: 99;
  width: 10%;
}
.bannercircle-contact-left img {
  width: 10.53rem;
  height: 10.31rem;
  position: absolute;
}
.bannercircle-contact-right {
  position: absolute;
  right: -88px;
  top: 61%;
}
.bannercircle-rotate {
  animation: animName 50s linear infinite;
}
@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* contact us */

/* scroll bar */
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: #800282;
  /* border-radius: 6px; */
  border: 3px solid var(--scrollbarBG);
}

.main-home-tag {
  overflow-x: clip;
}
.phome-esdm-btn-parent {
  display: flex;
  margin-top: 1rem;
}

.home-serv-btn---parent {
  display: flex;
  margin-top: 4.5rem;
  align-items: center;
  justify-content: center;
}
.home-about-btn---parent {
  display: flex;
  margin-top: 1.5rem;
}
.sec1-text ul {
  font-family: "Poppins", sans-serif;
}

.d-fferent-clouds {
  left: -13px !important;
}

.custom-approachpd {
  padding-bottom: 0.4rem;
}

/* Additonals */
.therapy-page-thrd-sec-img-right {
  width: 35%;
  opacity: 0.1;
}

.contact-page-main-parent .footer-top {
  display: none;
}
.home-head-cloud-parent img {
  width: 135% !important;
  left: -9px !important;
}
.footer-right-text div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer-top {
  padding-top: 2rem;
}
.input-forms,
.Other-information {
  width: 95%;
  margin: 0 auto;
}
.Other-information {
  padding: 0 1rem;
}

.yellow-btn-hover {
  border: 1px solid #e3d000;
  transition: all 0.3s ease-in-out;
}

.yellow-btn-hover:hover {
  background-color: transparent;
  color: #e3d000;
}
.ornge-btn-hover {
  border: 1px solid #fd832e;
  transition: all 0.3s ease-in-out;
}

.ornge-btn-hover:hover {
  background-color: transparent;
  color: #fd832e;
}
/* Additonals */
.contact2-main h2{
  color: #fd832e;
  font-family: "PT Sans", sans-serif;
  font-size: 60px;
  text-align: center;
  font-weight: bold;
}
.contact2-inner-content{
  width: 90%;
  margin: 0 auto;
  background-image: url(../images//contact2-inner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0;
}
.contact2-innerp{
   text-align: center;
   width: 60%;
   margin: 0 auto;
   font-size: 20px;
  color: #3d3d3d;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.contact2-form{
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 25px;
  padding-top: 30px;
  border-top: 2px solid #3d3d3d70;
}
.contact2-form h3{
  font-family: "PT Sans", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #3d3d3d;
}
.contact2-form p{
  color: #3d3d3d;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.contact2-form a{
  color: #3d3d3d;
  text-decoration: none;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.Contact2-map{
  margin-top: 40px;
  z-index: 99;
}
.bannercircle-contact2-right{
  z-index: -1;
  top: 45%;
}
.bannercircle-contact2-left2 img{
top: 70%;
    left: -4%;
    z-index: -1;
}
.About-travis{
  padding-top: 50px;
}
.therapiy-new-secadd{
  font-weight: 600 !important;
  font-family: 'Poppins', sans-serif;
  padding: 10px 0 0 0 !important;
}
.therapiy-new-secaddhh{
  padding-bottom: 20px;
}
.therapy-newsecadd-leftimg{
  margin-top: 180px;
  width: 90%;
}
.therapy-newsecadd-padding{
  padding-bottom: 65px;
}
.therapy-hyperlinks a{
  color: black;
}
.therapy-hyperlinks li{
  padding: 5px 0;
}
.early-start-bold{
  font-size: 19px !important;
  font-weight: bold !important;
}
.main-para{
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: initial;
  padding-left: 20px;
}
.public-holiday{
  font-weight: bold !important;
  text-decoration: underline;
}
.small-screen-header{
  display: none;
}
.header-res{
  display: none;
}

/* Modal */
.modal-wechat {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: clip;
}
.modal-content-wechat {
  background-color: white;
  margin: 9% auto;
  border: 1px solid #888;
  width: 40%;
  height: 60%;
  border-radius: 20px;
  z-index: 99;
  position: relative;
}
.close-btn-man svg {
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
.close-btn-man{
  background-color: red;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 5px solid #ffff;
  position: absolute;
      right: -15px;
    top: -15px;
}
.modal-content-wechat img{
width: 100%;
height: 100%;
object-fit: contain;
}
.HomeEsdmShape2{
  display: none;
}
.therapy-newsecadd-leftimg-res{
  display: none;
}
.gmap_canvas iframe{
  z-index: 1;
}
.homeabout-sec-content{
  width: 60%;
}
