@media only screen and (max-width: 1700px) {

  /* HOMEPAGE RESPONSIVE */
  .container-1440 {
    width: 88vw;
  }

  .contact2-form h3 {
    font-size: 28px;
  }

  .contact2-form p {
    font-size: 15px;
  }

  .sec1-text.Protext2 h5 {
    font-size: 14px !important;
  }

  img.therapy-sec2-pic {
    width: 29%;
  }

  .sec333 {
    padding-bottom: 60px !important;
  }

  .therapy-newsecadd-padding {
    padding-bottom: 125px !important;
  }

  .bannercircle-contact2-right {
    top: 55% !important;
  }

  .bannercircle-contact2-left2 img {
    top: 80%;
  }

  .banner-home-first-heading {
    font-size: 3.05rem;
  }

  .banner-home-second-heading {
    font-size: 3.9rem;
  }

  .banner-home-para {
    font-size: 16px;
  }

  .left-banner-home-btn {
    border-radius: 0.425rem;
    padding: 0.3rem 0.5rem;
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .header ul li a {
    font-size: 15px;
  }

  .right-banner-home-img-lower {
    width: 40rem;
    height: 30rem;
  }

  .therapy-sec2-pic {
    width: 80%;
  }

  .sec3 h6 {
    font-size: 27px;
  }

  .sec1bg-therapy {
    left: 32%;
    top: 26%;
    width: 30%;
  }

  .right-banner-home-img-upper {
    width: 80%;
  }

  .banner-home-bg-slide {
    font-size: 7.7rem;
    letter-spacing: 0.14rem;
    right: 15rem;
  }

  .banner-home-bg-slide-two {
    top: 36%;
    left: 38rem;
  }

  /* .content-parent-banner-home .banner-row {
    width: 90%;
    margin: 0 auto;
  } */
  .left-bottom-home-banner img {
    width: 63%;
  }

  .header-logo-li {
    text-align: center;
  }

  .header-logo-li img {
    width: 82%;
  }

  .right-banner-home-img {
    text-align: center;
  }

  /*  */
  .sec1 {
    margin-top: -13rem;
  }

  .sec1-text h2,
  .home-serv-heading,
  .home-esdm-heading,
  .heading-home-meet-team,
  .footer-map h2 {
    font-size: 45px;
  }

  .heading-home-raise {
    font-size: 25px;
  }

  .sec1-text p {
    padding: 7px 0;
    font-size: 14px;
    margin: 5px 0;
  }

  .sec1-text {
    padding-top: 60px;
    /* margin-left: 60px; */
  }

  .homeabout-sec-btm-left-circle img,
  .homeabout-sec-btm-right-circle img {
    width: 60%;
  }

  .home-serv-top-img {
    width: 10%;
  }

  .home-about-btn {
    font-size: 14px;
    border-radius: 0.525rem;
    padding: 0.4rem 0.8rem 0.55rem 0.8rem;
  }

  .home-about-btn svg {
    font-size: 13px;
    padding-left: 0.5rem;
  }

  .sec1-text {
    padding-left: 90px;
  }

  .sec1right {
    width: 38%;
  }

  .left-kid-home-serv-img {
    width: 80%;
  }

  .left-kid-home-serv-parent {
    transform: translate(2rem, -12.5rem);
  }

  .services-hometext {
    font-size: 17px;
  }

  .services-homeimg {
    max-width: 100px;
  }

  .home-services-sec {
    padding-bottom: 9rem;
    margin-top: 8rem;
  }

  .HomeEsdmShape-parent img {
    width: 100%;
  }

  .right-esdm-home-img-parent img {
    width: 67%;
  }

  .right-esdm-home-img-parent {
    right: 4rem;
    top: 10em;
  }

  .sub-heading-esdm-home {
    font-size: 16px;
  }

  .sub-para-esdm-home,
  .sub-content-esdm-bullet-points li {
    font-size: 13px;
  }

  .sub-para-esdm-home {
    margin-bottom: 0.8rem;
  }

  .heading-home-raise {
    margin-bottom: 1rem;
  }

  .heading-home-meet-team {
    margin-bottom: 2rem;
  }

  .right-home-meet-heading {
    font-size: 1.8rem;
  }

  .right-home-meet-para {
    font-size: 15px;
  }

  .footer-map p {
    padding: 12px;
    font-size: 13px;
  }

  .footer-top img {
    width: 26%;
  }

  .footer-map h3,
  .footer-left-text h4,
  .footer-right-text h4 {
    font-size: 33px;
  }

  .footer-right-text div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-right-text img {
    width: 26px;
  }

  .footer-left-text ul li {
    margin: 16px 0;
    font-size: 17px;
  }

  .footer-left-text ul li svg {
    font-size: 16px;
  }

  .bannerbottom {
    height: 13rem;
  }

  .homeabout-sec-btm-right-circle {
    right: 23rem;
  }

  .mapouter {
    height: 200px;
    width: 400px;
  }

  .gmap_canvas {
    height: 200px;
    width: 400px;
  }

  .HomeEsdmShape-parent {
    top: -10px;
  }

  .custom-approachpd {
    padding-bottom: 0;
  }

  .sec3-inner-content {
    padding: 80px 0;
  }

  .contact-page-main-parent .footer {
    margin-top: -60px;
  }

  .aboutmeet-mg-left {
    margin-left: -3rem;
  }

  /* HOMEPAGE RESPONSIVE */
  /*  */

  /* /////////Therapy//////// */
  .sec3Circle {
    top: -50px;
  }

  .sec2bg-therapy {
    width: 36%;
  }

  /* Contact us  */
  .banner-content {
    padding-top: 19%;
    display: flex;
    height: 500px;
  }

  .contact-infocontact-info {
    width: 75%;
    margin: 0 auto;
  }

  section.sec1-contact-page {
    margin-top: -16rem;
    z-index: 9;
    position: relative;
  }

  .bannercircle {
    position: absolute;
    bottom: 5%;
    left: 62%;
    z-index: 99;
  }

  .sec2-contact-page {
    padding-top: 10.5rem;
    padding-bottom: 8rem;
  }

  .text-info-contact-h1 {
    font-size: 50px;
  }

  .text-info-contact-h2 {
    font-size: 50px;
  }

  .Footer-content {
    padding-top: 200px;
  }

  /* Contact us  */

  /* ///////About//////// */
  .Therapy-Registration-Modal h2 {
    font-size: 40px;
  }

  .Therapy-Registration-Modal p {
    font-size: 14px;
  }

  .Service-box {
    width: 430px;
    height: 380px;
  }

  .Service-box p {
    margin-top: 17px;
    font-size: 12px;
  }

  .Service-box h3 {
    font-size: 18px;
  }

  .sec2 h2 {
    font-size: 45px;
    padding: 10px 0 0 0;
  }

  .Therapy-Registration-Modal h3 {
    font-size: 18px;
  }

  .information-forms label {
    font-size: 13px;
  }

  /* .Banner-pic img {
    top: 30%;
    width: 45%;
  } */
  .bannercircle {
    bottom: 0%;
    left: 60%;
    width: 11%;
  }

  .sec1right {
    width: 35%;
  }

  .sec1circle {
    top: 45%;
    width: 10%;
  }

  .Banner-text h3 {
    font-size: 18px;
  }

  .Banner-text h1 {
    font-size: 60px;
  }

  .sec1-text {
    padding-top: 40px;
    margin-left: 25px;
  }

  .sec1-text ul li {
    font-size: 14px;
  }

  .text-info-contact-p {
    font-size: 14px;
  }

  .sec3 h5 {
    font-size: 14px;
  }

  .info-left {
    width: 32.9rem;
  }

  .info-right {
    width: 32.9rem;
  }

  .bannercircle-contact-left {
    top: 32%;
  }

  .bannercircle-contact-right {
    top: 65%;
  }

  .sec-solongop {
    font-size: 17px;
  }

  .sec3-content h2 {
    font-size: 50px;
  }

  .sec3 h3 {
    font-size: 23px;
  }

  .sec3-Certified li {
    color: #ffff;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    margin: 5px 0;
    font-weight: 500;
  }

  .sec3-Sharon img {
    width: 74%;
  }

  .sec3 {
    padding: 10px 0;
  }

  .sec3-importance p {
    margin: 14px 0;
    font-size: 12px;
  }

  /* PROGRAM PAGE RESPONSIVE  */
  .ClinicalImg1,
  .ClinicalImg2 {
    width: 78%;
  }

  .program-sec .sec1-text h6 {
    font-size: 36px;
  }

  .program-sec .sec1-text h5 {
    font-size: 14px;
  }

  .program-sec .sec1-text h3 {
    font-size: 18px;
    margin-bottom: 0;
  }

  .program-sec .sec1-text p {
    font-size: 14px;
    margin-top: 0;
  }

  .program .bannercircle-rotate {
    width: 7%;
  }

  .program-sec .container-1440 {
    width: 82vw;
  }

  .program-sec ul {
    margin: 7px 0 18px 18px;
  }

  .program-sec ul {
    gap: 0.6rem;
  }

  .program-padding-sec {
    padding: 2rem 0;
  }

  .program-padding-sec .sec1-text {
    margin-left: 0;
  }

  .circle1-sec3 {
    width: 7% !important;
  }

  .Protext5 h4 {
    font-size: 1.5rem;
  }

  /* PROGRAM PAGE RESPONSIVE  */
  .close-btn-man {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    right: -12px;
    top: -12px;
  }

  .close-btn-man svg {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1366px) {

  /* HOMEPAGE RESPONSIVE */
  .banner-home-first-heading {
    font-size: 2.5rem;
  }

  .bannercircle-contact2-right {
    top: 49% !important;
  }

  .bannercircle-contact2-left2 img {
    top: 78%;
  }

  .contact2-innerp {
    font-size: 16px;
  }

  .contact2-form h3 {
    font-size: 20px;
  }

  .contact2-main h2 {
    font-size: 45px;
  }

  .contact2-form p {
    color: #3d3d3d;
    font-size: 13px;
    color: #3d3d3d;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
  }

  .contact2-form svg {
    font-size: 12px;
  }

  .banner-home-second-heading {
    font-size: 3.1rem;
  }

  .banner-home-para {
    font-size: 14px;
  }

  .left-banner-home-btn {
    font-size: 14px;
  }

  .banner-home-bg-slide {
    font-size: 6.5rem;
    right: 12rem;
  }

  .banner-home-bg-slide-two {
    top: 36%;
    left: 31rem;
  }

  .left-bottom-home-banner img {
    width: 55%;
  }

  .header ul li a {
    font-size: 13px;
  }

  .header-register {
    padding: 8px 16px;
    border-radius: 4px;
  }

  .right-banner-home-img-lower {
    width: 34rem;
    height: 25rem;
  }

  .header-logo-li img {
    width: 70%;
  }

  .right-banner-home-img-upper {
    width: 70%;
  }

  /*  */
  .sec1 {
    margin-top: -10.5rem;
  }

  .sec1-text h2,
  .home-serv-heading,
  .home-esdm-heading,
  .heading-home-meet-team,
  .footer-map h2 {
    font-size: 38px;
  }

  .sec1-text {
    padding-top: 40px;
    /* margin-left: 65px; */
  }

  .homeabout-sec-btm-left-circle img,
  .homeabout-sec-btm-right-circle img {
    width: 55%;
  }

  .sec1-text p {
    padding: 5px 0;
    font-size: 12px;
  }

  .home-about-btn {
    font-size: 12px;
    border-radius: 0.425rem;
    padding: 0.3rem 0.6rem 0.45rem 0.6rem;
  }

  .home-about-btn svg {
    font-size: 12px;
  }

  .left-kid-home-serv-img {
    width: 70%;
  }

  .home-serv-heading {
    margin-bottom: 1.5rem;
  }

  .services-homeimg {
    max-width: 75px;
  }

  .services-hometext {
    font-size: 14px;
  }

  .home-services-row-gap {
    margin-bottom: 1.5rem;
  }

  .home-services-sec {
    padding-bottom: 9rem;
    margin-top: 6rem;
  }

  .col-content-homesdm {
    gap: 0.3rem;
    padding-top: 3.5rem;
  }

  .sub-heading-esdm-home {
    font-size: 14px;
  }

  .sub-para-esdm-home,
  .sub-content-esdm-bullet-points li {
    font-size: 12px;
  }

  .home-raise-sec {
    padding: 6rem 0 2.5rem 0;
  }

  .right-esdm-home-img-parent img {
    width: 60%;
  }

  .right-esdm-home-img-parent {
    right: 2rem;
    top: 9.3rem;
  }

  .home-esdm-sec {
    padding-bottom: 4.5rem;
  }

  .heading-home-raise {
    font-size: 22px;
  }

  .heading-home-meet-team {
    margin-bottom: 1.5rem;
  }

  .right-home-meet-heading {
    font-size: 1.5rem;
  }

  .right-home-meet-para {
    font-size: 13px;
  }

  .footer-top img {
    width: 25%;
  }

  .footer-map h2 {
    padding-top: 60px;
  }

  .footer-map p {
    padding: 9px;
    font-size: 12px;
  }

  .footer-map h3,
  .footer-left-text h4,
  .footer-right-text h4 {
    font-size: 28px;
  }

  .footer-left-text ul li svg {
    font-size: 14px;
  }

  .footer-left-text ul li {
    margin: 13px 0;
    font-size: 15px;
  }

  .footer-right-text img {
    width: 24px;
  }

  .footer-right-text div {
    width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .bannerbottom {
    height: 11rem;
  }

  .homeabout-sec-btm-right-circle {
    right: 18rem;
    top: -6rem;
  }

  .mapouter {
    height: 170px;
    width: 370px;
  }

  .gmap_canvas {
    height: 170px;
    width: 370px;
  }

  .Protext1 h5 {
    line-height: 19px;
  }

  .sec3-inner-content {
    padding: 70px 0;
  }

  .home-serv-btn---parent {
    margin-top: 3.5rem;
  }

  .sec3 h5 {
    line-height: 19px;
  }

  /*  */
  /* HOMEPAGE RESPONSIVE */
  /* contact Page */
  section.sec1-contact-page {
    margin-top: -14rem;
  }

  .sec2-contact-page {
    padding-top: 6.5rem;
  }

  .bannercircle-contact-right {
    right: -106px;
  }

  .bannercircle-contact-left {
    top: 25%;
  }

  .text-info-contact-h1 {
    font-size: 46px;
  }

  .text-info-contact-h2 {
    font-size: 46px;
  }

  .Banner-text h3 {
    font-size: 16px;
  }

  .Banner-text h1 {
    font-size: 45px;
  }

  .contact-send-btn {
    width: 7.31rem;
    height: 3rem;
    margin-top: 2rem;
  }

  .bannerbottom-contact {
    padding-top: 1rem;
  }

  .Footer-content {
    padding-top: 120px;
  }

  /* contact Page */

  /* ////////About///////// */
  .Service-box h3 {
    font-size: 16px;
  }

  .Service-box p {
    margin-top: 11px;
    font-size: 12px;
  }

  .Service-box {
    width: 360px;
    height: 380px;
  }

  .service-circle {
    right: -65px;
    top: -54px;
    width: 12%;
  }

  .service-bg {
    left: -8%;
    top: 45%;
    width: 40%;
  }

  .sec1-text.Protext2 h5 {
    font-size: 12px !important;
  }

  /* ////////////footer////////// */
  .footer {
    margin-top: -105px;
  }

  /* PROGRAM PAGE RESPONSIVE  */
  .program-sec .sec1-text h6 {
    font-size: 32px;
  }

  .program-sec .sec1-text h5 {
    font-size: 12px;
  }

  .program-sec .sec1-text p {
    font-size: 12px;
  }

  .sec1-text ul li {
    font-size: 12px;
  }

  .ClinicalImg1,
  .ClinicalImg2 {
    width: 75%;
  }

  .Protext5 h4 {
    font-size: 1.3rem;
  }

  /* PROGRAM PAGE RESPONSIVE  */
  .close-btn-man {
    height: 26px;
    width: 26px;
    right: -9px;
    top: -9px;
  }
}

@media only screen and (max-width: 999px) {
  /* .pd-top-100{
    position: relative;
    z-index: 1;
  } */
      .aboutmeet-mg-left {
        margin-left: 0;
    }
  .home-banner-header-parent-res {
    z-index: 99 !important;
  }
  .responsive-header {
    display: block;
    position: relative;
    z-index: 3;
  }

  .Header-Main {
    display: none;
  }
  .header ul .hovermenus img {
    width: 120% !important;
  }
  .small-screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    position: absolute;
    top: 0;
    height: 100px;
    width: 90%;
    z-index: 9;
    margin-left: 2.3rem;
    /* grid-template-columns: 32% 45% 23%; */
  }
  .hovermenus.home-head-cloud-parent.active img {
    opacity: 1;
  }

  .hovermenus.home-head-cloud-parent.active span {
    color: #001a62;
  }

  .small-screen-header-logo img {
    width: 120px;
    margin-left: 3rem;
  }

  .main-home-tag,
  .contact-page-main-parent {
    overflow-x: hidden;
  }

  .small-screen-toggle {
    /* position: absolute;
    right: 20px;
    top: 30px; */
    font-size: 30px;
    color: white;
  }

  .responsive-header {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999 !important;
    transition: all 0.5s ease-in-out;
    background: radial-gradient(#d23fd9, #b104b5) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }

  .responsive-header .header {
    display: block;
    margin-top: 8rem;
  }

  .header ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

  }

  .header ul li a {
    font-size: 16px;
  }

  .banner-row {
    display: block;
    justify-content: center;
    text-align: center;
  }

  .solongo-banner-res {
    width: 100%;
  }

  .banner-home-second-heading {
    font-size: 4rem;
  }

  .banner-home-first-heading {
    font-size: 3rem;
  }

  .left-banner-home-btn-parent {
    position: absolute;
    top: 80%;
    left: 38%;
    flex-direction: column;
  }

  .solongo-btn-res {
    padding: 0.3rem 1rem;
  }

  .right-banner-home-img-upper {
    width: 62%;
  }

  .right-banner-home-img-lower {
    width: 50rem;
    height: 50rem;
  }

  .footer-top {
    flex-direction: column-reverse;
    padding-right: 0;
    width: 90%;
    margin: 0 auto;
    background-color: initial;
    margin-top: -3px;
  }

  .footer-map {
    width: 100%;
    background-image: initial;
  }

  .Footer-content {
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }

  .container-1440 {
    width: 90%;
    margin: 0 auto;
  }

  .social-link-head2 {
    display: none;
  }

  .footer-right-text div {
    width: 40%;
  }

  .footer-and-contact-parent {
    background-color: #E5FBFF;
    background-image: url(https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/res-contact-us.png);
    background-size: cover;

  }

  .map-contact-parent,
  .mapouter {
    display: initial;
  }

  .gmap_canvas {
    width: 100%;
    margin-bottom: 22rem;
  }

  .footer-map h2 {
    padding-top: 190px;
  }

  .footer-left-text h4 {
    text-align: center;
  }

  .footer-map h3,
  .footer-left-text h4,
  .footer-right-text h4 {
    font-size: 35px;
  }

  .footer-top img {
    width: 25%;
    position: absolute;
  }

  .footer-right-text img {
    width: 35px;
  }

  .footer-map p {
    padding: initial;
    font-size: 16px;
  }

  .footer {
    background-image: url(https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/footer-bg.png);
  }

  .home-meet-sec-row {
    width: 100%;
    flex-direction: column;
  }

  .heading-home-meet-team {
    margin-left: initial;
  }

  .right-content-home-meet {
    margin-left: initial;
  }

  .home-meet-sec {
    padding-bottom: 1rem;
  }

  .sharon-res {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .sharon-res-first {
    text-align: center;
  }

  .left-img-team-meet img {
    width: 40%;
    margin-bottom: 1rem;
  }

  .heading-home-meet-team {
    text-align: center !important;
  }

  .home-meet-btn-parent {
    justify-content: center;
  }

  .Meet-our-team-bg {
    position: absolute;
    left: 20%;
    top: -8%;
    opacity: 0.1;
    width: 60%;
    z-index: -1;
  }

  .content-parent-homeESDM,
  .col-content-homesdm {
    background-color: #fd832e;
    margin-bottom: -1px;
  }

  .sub-content-esdm-bullet-points div {
    flex-direction: column;
  }

  .home-services-sec {
    padding-bottom: 0;
  }

  .home-esdm-sec {
    padding-bottom: 33rem;
    margin-top: -2px;
  }

  .HomeEsdmShape-parent {
    top: 99%;
    background-color: #E5FBFF;
  }

  .HomeEsdmShape2 {
    display: block;
  }

  .HomeEsdmShape {
    display: none;
  }

  .HomeEsdmShape-parent img {
    width: 101vw;
  }

  .right-esdm-home-img-parent {
    right: -4rem;
    top: 110%;
  }

  .home-esdm-heading {
    color: #ffff;
  }

  .sub-heading-esdm-home {
    font-size: 14px;
    color: #ffff;
  }

  .sub-para-esdm-home,
  .sub-content-esdm-bullet-points li {
    font-size: 12px;
    color: #ffff;
  }

  .maincol-content-homesdm {
    width: 100%;
  }

  .gmap_canvas iframe {
    width: 100% !important;
  }

  .res-our-service {
    flex-direction: column;
  }

  .res-our-service-sec {
    width: 100%;
  }

  .home-services-row-gap-res {
    margin-top: 12rem;
  }

  .home-serv-heading {
    margin-bottom: 1.5rem;
    margin-left: 10rem;
  }

  .left-kid-home-serv-parent {
    transform: translate(3rem, -13rem);
  }

  .left-kid-home-serv-img {
    width: 50%;
  }

  .sec1right {
    display: none;
  }

  .sec1-text {
    padding-left: initial;
    margin-left: 0;
    text-align: center;
  }

  .home-about-min-parent {
    position: relative;
    z-index: 9;
    margin-top: -2PX;
  }

  .home-about-btn---parent {
    justify-content: center;
  }
  .homeabout-sec-btm-right-circle {
    right: -2rem;
    top: -8rem;
}
.responsive-header .small-screen-header-logo img{
  display: none;
  }
  .responsive-header .header-register{
    display: none;
  }
  .homeabout-sec-btm-left-circle img, .homeabout-sec-btm-right-circle img {
    width: 40%;
}
  /* ///////////////About////////////// */
  .banner-content {
    padding-top: 25%;
    height: initial;
}
.About-banner{
  height: 55vh;
}
.Banner-pic img{
  width: 60%;
}
.bannerbottom{
  width: 130%;
}
.Banner-text {
  width: 100%;
  text-align: center;
}
.sec2-service{
  flex-direction: column;
  gap: 2rem;
}
.Service-box {
  width: 430px;
  height: 400px;
}    .bannercircle {
  bottom: 10%;
  left: 74%;
}
.service-bg {
  left: 15%;
  top: -9%;
  width: 60%;
}
.Service-box img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.sec-solongop {
  font-size: 20px;
}
.sec3 {
  text-align: center;
}
.sec3-Sharon{
  flex-direction: column;
}
.About-travis-content{
  background-image: url(https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/travis-myors-bg-res.png) !important;
}
.sec3-content{
  background-image: url(https://solidificatiosolution.s3.ap-southeast-2.amazonaws.com/travis-myors-res.png);
}
.sec3-content h2 {
  white-space: nowrap;
  font-size: 40px;
  padding: 20px 0;
}
.sec3-inner-content {
  padding: 40px 0;
}
.gmap_canvas{
  height: 250px;
}
.sec3-inner-content{
  width: 70%;
}
.sec3-Sharon img {
  width: 65%;
}
.sec1-text-res h2 {
  padding-bottom: 370px;
}
.sec1-text-res{
  padding-top: 0;
}
.therapy-sec2-pic-res {
  top: 24%;
  right: 32% !important;
  width: 45% !important;
}
.therapy-sec2-childrenpic{
  top: 33%;
  right: 24% !important;
  width: 30% !important;
}
.therapy-sec2-pic-new{
  top: 40%;
  right: 46% !important;
  width: 25% !important;
}
img.sec2bg-therapy2 {
  width: 70%;
}
.sec3Circle{
  left: 0;
}
.Why-Therapy-Choose h2{
  text-align: center !important;
}
.sec1-text-edsm-res{
  padding-top: 370px;
}
.sec3Img-new-add1 {
  right: 22%;
  width: 66%;
  top: -3%;
}
.sec3Img-new-add2 {
  position: absolute;
  right: 14%;
  top: 16%;
  width: 40%;
}
.Enhanced-social-skills{
  text-align: left;
}
.therapiy-new-secadd{
  text-align: left;
}
.therapy-newsecadd-leftimg{
display: none;
}
.therapy-newsecadd-leftimg-res{
  display: block;
  margin-top: 20px;
  margin-left: 50px;
  width: 80%;
}
.Protext2{
  margin-top: 0;
}
.therapiy-new-secadd-res{
  text-align: center;
}
.therapy-hyperlinks {
  text-align: left;
}
.therapy-newsecadd-padding{
  padding-bottom: 0 !important;
}
.early-start-bold {
  font-size: 16px !important;
}
.Protext111 h6{
padding-bottom: 370px;
}
.ClinicalImg1 {
  width: 50%;
  position: absolute;
  top: 21%;
  left: 25%;
}
.ClinicalImg2 {
  width: 35%;
  position: absolute;
  top: 33%;
  left: 20%;
}
.Protext222 h6{
padding-top: 350px;
}
.KinderImg1 {
  width: 30%;
  top: 14%;
  left: 44%;
}
.KinderImg2 {
  width: 30%;
  top: 18%;
  left: 24%;
}
.KinderImg3 {
  width: 45%;
  top: 2%;
  left: 25%;
}
.Interventions-res{
  text-align: left;
}
.Interventions-res h6{
  text-align: center;
}
.CasualProgram-res h6{
  padding-top: 350px;
}
.CasualImg1{
  top: 12%;
  width: 35%;
    right: 22%;
}
.CasualImg2{
   top: 15%;
  width: 35%;
    right: 45%;
}
.CasualImg3{
  top: 1%;
  width: 50%;
    right: 25%;
}
.JuniorSocial-res h6{
  padding-top: 350px;
}
.ESDMParent-res h6{
  padding-top: 350px;
}
.EnrollImg5 {
  position: absolute;
  top: 2%;
  width: 40%;
  left:15%;
}
.EnrollImg3 {
  position: absolute;
  top: 2%;
  width: 40%;
  left:52%;
  z-index: -9;
}
.EnrollImg1 {
  position: absolute;
  top: 12%;
  width: 35%;
  left:10%;
}
.EnrollImg2 {
  position: absolute;
  top: 12%;
  width: 35%;
  left:34%;
}
.EnrollImg4 {
  position: absolute;
  top: 12%;
  width: 35%;
  left:58%;
}
.modal-content-wechat {
  margin: 40% auto;
  width: 80%;
  height: 40%;
}
.contact2-form {
  width: 100%;
  border-top: none;
  flex-direction: column;
  align-items: center;
}
.CertifiedESDM-res{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
.bannercircle-contact-left img{
  width: 11%;
  height: initial;
}
.bannercircle-contact-right  .bannercircle-contact2-right img{
  width: 11%;
  height: initial;
}
.bannercircle-res img{
  width: 50%;
  height: initial;
}
.modal-content {
  width: 95%;
}
.Register-modal .modal-dialog.modal-dialog-centered {
  max-width: 80%;
}
.Therapy-Registration-Modal h2 {
  font-size: 30px;
}
.Therapy-Registration-Modal p {
  font-size: 11px;
}
.Therapy-Registration-Modal h3 {
  font-size: 13px;
  margin-top: 10px;
}
.information-forms label {
  font-size: 11px;
}
.input-forms, .Other-information {
  width: 100%;
}
.information-forms input, .information-forms textarea {
  border-radius: 5px;
  padding: 5px;
}
.information-forms {
  margin-bottom: 10px;
}
.Modal-send-btn {
  margin-top: 20px;
}
.modal-dialog-centered{
  min-height: initial;
}
.Register-modal .modal-dialog.modal-dialog-centered {
  margin-top: 6rem;
}
.modal-xmark {
  right: -20px;
  top: -15px;
}
.pd-top-100 {
    padding: 0;
}

.home-banner-header-parent {
    position: inherit;
}
.homeabout-sec-content{
  width: 100%;
}
.program-sec ul {
  margin: 5px 0;
}
.sec1-text ul{
  margin-left: 0.7rem;
}
}

@media only screen and (max-width: 767px) {

  .header-register {
    padding: 3px 7px;
    border-radius: 4px;
    font-size: 13px;
}
.content-parent-home-services-sec{
  margin-top: -1rem;
}
.sec2-contact-page {
  margin-top: -2px;
}
.bannercircle-res {
  bottom: 25% !important;
}
.small-screen-header{
  margin-left: 1.2rem;
  height: 65px;
}
.small-screen-header-logo img {
  width: 70px;
}
.small-screen-toggle {
  font-size: 24px;
  color: white;
}
.banner-home-first-heading {
  font-size: 2rem;
}
.banner-home-second-heading {
  font-size: 3rem;
}
.banner-home-para {
  font-size: 10px;
}
.right-banner-home-img-upper {
  width: 50%;
}
.right-banner-home-img-lower {
  width: 20rem;
  height: 20rem;
}
.content-parent-banner-home {
  height: 85%;
}
.left-banner-home-btn-parent {
  top: 84%;
  left: 23%;
}
.left-bottom-home-banner img{
  display: none;
}
.bannerbottom {
  height: 5rem;
}
.bannerbottom{
  width: 130%;
}
.homeabout-sec-btm-left-circle img, .homeabout-sec-btm-right-circle img {
  width: 25%;
}
.homeabout-sec-btm-right-circle {
  right: -7rem;
  top: -3rem;
}
.sec1-text p {
  padding: 0 !important;
  font-size: 9px;
}
.homeabout-sec-btm-left-circle {
  position: absolute;
  bottom: -2rem;
  left: 0rem;
}
.home-serv-top-img {
  width: 20%;
}
.home-services-row-gap-res {
  margin-top: 5rem;
}
.left-kid-home-serv-parent {
  transform: translate(1rem, -10rem);

}
.home-serv-heading {
  margin-bottom: 1rem;
  margin-left: 5rem;
}
.left-kid-home-serv-img {
  width: 40%;
}
.sec1-text h2, .home-serv-heading, .heading-home-meet-team, .footer-map h2 {
    font-size: 25px;
}
.home-esdm-heading{
  font-size: 20px;
}
.services-homeimg {
  max-width: 55px;
}
.services-hometext {
  font-size: 10px;
  width: 90%;
  margin: 0 auto;
}
.sub-para-esdm-home, .sub-content-esdm-bullet-points li {
  font-size: 8px;
}
.sub-heading-esdm-home {
  font-size: 11px;
}
.home-about-btn {
  font-size: 10px;
  border-radius: 0.425rem;
  padding: 0.2rem 0.5rem 0.35rem 0.5rem;
}
.home-about-btn svg {
  font-size: 10px;
}
.right-esdm-home-img-parent img {
  width: 30%;
}
.right-esdm-home-img-parent {
  right: -10rem;
  top: 105%;
}
.home-esdm-sec {
  padding-bottom: 16rem;
}
.heading-home-raise {
  font-size: 11px;
}
 .heading-home-meet-team {
  font-size: 20px;
  margin-bottom: 10px;
}
.right-home-meet-heading {
  font-size: 1rem;
  margin-bottom: 0.3rem !important;
}
.right-home-meet-para {
  font-size: 9px;
}
.footer-map p {
  padding: initial;
  font-size: 9px;
}
.footer-map h2 {
  padding-top: 150px;
}
.footer-map h3, .footer-left-text h4, .footer-right-text h4 {
  font-size: 25px;
}
.mobile-icon{
  margin-bottom: 0.4rem;
}
.mobile-svg{
  font-size: 12px;
}
.footer-and-contact-parent {
  background-size: 130% 100%;
}
.gmap_canvas {
  margin-bottom: 17rem;
}
.footer-top img {
  width: 35%;
}
.Footer-content {
  padding-top: 90px;
}
.footer-right-text img {
  width: 25px;
}
.footer-right-text div {
  width: 50%;
}
.footer-left-text ul li {
  margin: 10px 0;
  font-size: 11px;
  font-weight: 500;
  font-size: 12px;
}
.Banner-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-content {
  padding-top: 17%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: initial;
  align-items: center;
}
.Banner-text h3 {
  text-align: center;
}
.About-banner {
 
  height: 35vh;
}
.cloudimg {
  margin-top: 90px;
}
.bannercircle {

  left: 76%;
}
/* /////////////about///////////////////// */
.Banner-text h3 {
  font-size: 12px;
}
.Banner-text h1 {
  font-size: 35px;
}
.banner-content {
  padding-top: 30%;
}
.About-banner {
  height: 37vh;
}
.sec1-text{
  padding-top: 0;
}
.sec1circle{
  top: 100%;
}
.bannercircle {
  bottom: 5%;
}
.Service-box img {
  height: 60px;
  width: 60px;
}
.Service-box {
  width: 330px;
  height: 320px;
}
.Service-box p {
  font-size: 10px;
  width: 85%;
  margin: 0 auto;
}
.service-bg {
  top: -6%;
}
.gmap_canvas {
  height: 200px;
}
.sec3-content{
  margin: 0;
}
.sec3-content h2 {
  font-size: 21px;
  padding: 10px 0;
}
.sec3-Sharon img {
  width: 50%;
}
.sec3-inner-content {
  width: 80%;
}
.sec3 h3 {
  font-size: 17px;
}
.sec3-Certified li {
  font-size: 10px;
}
.sec3-importance p {
  font-size: 10px;
  margin: 10px 0;
}
.sec3-inner-content {
  padding:20px  0 0 0;
}
.sec-solongop {
  font-size: 12px;
}
.sec1-text-res h2 {
  padding-bottom: 200px;
}
.therapy-sec2-pic-res {
  top: 16%;
}
.therapy-sec2-childrenpic {
  top: 25%;
}
.therapy-sec2-pic-new {
  top: 29%;
}
.Therapysec1 {
  padding-bottom: 0;
}
.sec1-text-edsm-res {
  padding-top: 125px;
}
.sec3Img-new-add1 {
  top: -14%;
}
.sec3Img-new-add2 {
  top: -2%;
  width: 40%;
}
.sec3 h6 {
  font-size: 22px;
}
.sec1-text h2, .home-serv-heading, .heading-home-meet-team, .footer-map h2 {
  font-size: 22px;
}
.sec3 h5 {
  font-size: 11px;
}
.sec1-text ul li {
  font-size: 9px;
}
.sec3 h5 {
  font-size: 10px;
}
.therapy-newsecadd-leftimg-res {
  margin-left: 35px;
}
.early-start-bold {
  font-size: 12px !important;
}
.Protext2 ul {
  margin-bottom: 15px;
}
.sec1-text.Protext2 h5 {
  font-size: 11px !important;
  padding-left: 0;
}
.sec1-text-edsm-Research{
  padding-top: 120px;
}
.sec3 h6 {
  font-size: 19px;
}
.program-sec .sec1-text h6 {
  font-size: 19px;
}
.program-sec .sec1-text h5 {
  font-size: 11px;
}
.ClinicalImg1 {
  top: 15%;
}
.ClinicalImg2 {
  top: 22%;
}
.Protext111 h6 {
  padding-bottom: 190px;
}
.program-sec .sec1-text p {
  font-size: 10px;
}
.program-sec .sec1-text h5 {
  font-size: 10px;
}
.Protext222 h6 {
  padding-top: 80px;
}
.KinderImg1 {
  top: 11%;
}
.KinderImg2 {
  top: 11%;
}
.program-sec .sec1-text h3 {
  font-size: 11px;
}
.CasualProgram-res h6 {
  padding-top: 190px;
}
.CasualImg1 {
  top: 10%;
}
.CasualImg2 {
  top: 10%;
}
.JuniorSocial-res h6 {
  padding-top: 80px;
}
.ESDMParent-res h6 {
  padding-top: 200px;
}
.EnrollImg1 {
  top: 8%;
}
.EnrollImg4 {
  top: 8%;
}
.EnrollImg2 {
  top: 10%;
}
.contact2-main h2 {
  font-size: 35px;
}
.contact2-innerp {
  width: 90%;
}
.contact2-innerp {
  font-size: 10px;
}
.contact2-form p {
  color: #3d3d3d;
  font-size: 10px;
}
.cloudimg-contact{
  margin-top: 90px;
}
.Therapy-Registration-Modal h2 {
  font-size: 18px;
}
.Therapy-Registration-Modal p {
  font-size: 8px;
  margin-bottom: 0.5rem;
}
.Therapy-Registration-Modal h3 {
  font-size: 9px;
  margin-top: 0;
}
.modal-xmark {
  font-size: 17px;
  right: -12px;
  top: -7px;
}
.information-forms label {
  font-size: 10px;
}
.information-forms input, .information-forms textarea {
  border-radius: 5px;
  padding: 1px;
  font-size: 14px;
}
.information-forms {
  margin-bottom: 4px;
}
.Modal-send-btn .send {
  padding: 5px 20px;
  font-size: 16px;
}
.Register-modal .modal-dialog.modal-dialog-centered {
  max-width: 90%;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
}
}

@media only screen and (max-width: 390px) {
  .About-banner {
    height: 50vh;
}
.Banner-text h3 {
  font-size: 10px;
}
.small-screen-header {
  margin-left: 1.1rem;
}
.banner-content {
  padding-top: 38%;
}
}